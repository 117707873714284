import React, { useEffect, useState } from "react";
import { BackGroundAnimationColor } from "../../components/index";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  const [isTablet, setIsTablet] = useState(false);
  const location = useLocation();
  const canonicalUrl = `https://doorclosers.me${location.pathname}`;
  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 884);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!isTablet && <BackGroundAnimationColor />}
      <section className="container d-flex flex-column align-items-center first-section-padding text-white">
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
        <h1 className="header-under-line responsive-text-60 text-white text-center">
          About Us
        </h1>
        <br /> <br />
        <p className="responsive-text-36" id="team">
          At Door Closers, our journey began with a shared frustration and a
          bold vision. Our Founder, Joseph, alongside co-founders Sary, George,
          and Quincy, each brought unique expertise cultivated through years in
          the trenches of various marketing agencies. Despite the diversity of
          their experiences, they all witnessed a common flaw: a pervasive gap
          between the value promised and the value delivered.
        </p>
        <br />
        <p className="responsive-text-36">
          Driven by this insight, Door Closers emerged as a solution to this
          industry-wide challenge. We started small, armed with a singular
          client and a determination to redefine what it means to partner with a
          marketing agency. From those humble beginnings, we've blossomed into a
          multifaceted force, offering over 25 various services tailored to meet
          the evolving needs of our clients.
        </p>
        <br />
        <p className="responsive-text-36" id="goal">
          Our mission is clear: through this year 2024, we aim to empower all
          businesses on our side to unlock their full potential. We're fully
          capable of achieving this by leveraging our expertise in workflows,
          workforce management, operations, and unwavering support.
        </p>
        <br />
        <p className="responsive-text-36" id="work">
          At Door Closers, we don't just offer services, we cultivate
          partnerships. We understand the struggle of building a cohesive team
          aligned with your goals, and we're here to bridge that gap. Our
          commitment is to make achieving your goals not only achievable but
          also seamless and rewarding.
        </p>
        <br />
        <p className="responsive-text-36" id="vision">
          Our vision extends beyond mere success for our clients. We aspire to
          be the cornerstone of growth for small businesses, the catalyst for
          scaling enterprises, and the trusted ally for established ventures.
          With our dedicated teams at the helm, Door Closers isn't just a
          service provider.. we're your growth engine, your problem-solver, and
          your steadfast companion on the path to success.
        </p>
      </section>
    </>
  );
};

export default AboutUs;
