import React, { useEffect, useState } from "react";
import { useLoading } from "./LoadingContext";
import "./LoadingScreen.css";

const LoadingScreen = ({ children, delay = 2000 }) => {
  const { loading, setLoading } = useLoading();
  const [loadingg, setLoadingg] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingg(false);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  useEffect(() => {
    let timer;
    if (loading) {
      timer = setTimeout(() => {
        setLoading(false);
      }, delay);
    }

    return () => clearTimeout(timer);
  }, [loading, delay, setLoading]);

  return (
    <>
      {(loading || loadingg) && (
        <div className="loader-container">
          <svg
            className="rotate-svg"
            viewBox="0 0 270 270"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle1"
              cx="135"
              cy="135"
              r="105"
              fill="#FF9F00"
            />
            <circle
              className="circle2"
              cx="135"
              cy="135"
              r="133.5"
              stroke="#FF9F00"
              strokeWidth="3"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M146.812 133.312C146.812 138.904 142.279 143.438 136.688 143.438C131.096 143.438 126.562 138.904 126.562 133.312C126.562 127.721 131.096 123.188 136.688 123.188C142.279 123.188 146.812 127.721 146.812 133.312ZM148.5 166.734C162.264 161.869 172.125 148.742 172.125 133.312C172.125 113.741 156.259 97.875 136.688 97.875C117.116 97.875 101.25 113.741 101.25 133.312C101.25 148.742 111.111 161.869 124.875 166.734L124.875 199.8C124.875 206.883 130.164 212.625 136.688 212.625C143.211 212.625 148.5 206.883 148.5 199.8L148.5 166.734Z"
              fill="black"
            />
          </svg>
        </div>
      )}
      {children}
    </>
  );
};

export default LoadingScreen;
