import React, { useEffect, useState } from "react";

import "./OutSourcing.css";
import {
  BackGroundAnimationColor,
  Calendar,
  ContactForm,
} from "../../components/index";
import OutSourcingarrow1 from "../../assets/images/Layer_1-1.png";
import OutSourcingarrow2 from "../../assets/images/Layer_1.png";
import dcOutsourcinglogo from "../../assets/images/logo.png";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const OutSourcing = () => {
  const [isTablet, setIsTablet] = useState(false);
  const location = useLocation();
  const canonicalUrl = `https://doorclosers.me${location.pathname}`;
  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 884);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {!isTablet && <BackGroundAnimationColor />}

      <div className="Arrows justify-content-center first-section-padding text-white">
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
        <div className="d-flex flex-column align-items-center">
          <img
            src={OutSourcingarrow1}
            alt="Arrow 1"
            className="arrow1 animated-up-down"
          />
          <img
            src={OutSourcingarrow2}
            alt="Arrow 2"
            className="arrow2 animated-up-down2"
          />
        </div>
      </div>

      <section className="outsourcingtext">
        <div
          className="textright responsive-text-24  text-white"
          id="delegate-operations"
        >
          <h2>
            <span className="highlight">Executive assistance</span>
          </h2>
          <p>
            Streamlined Administrative Tasks Efficient Scheduling, And Enhanced
            Communication Freeing Up Executives To Focus On Strategic
            Initiatives.
          </p>
        </div>
        <div className="textleft responsive-text-24  text-white ">
          <h2>
            <span className="highlight">Payroll Management</span>
          </h2>
          <p>
            Accurate, Compliant, And Efficient Payroll Services Reducing
            Administrative Burden Ensuring Timely Payments.
          </p>
        </div>
        <div
          className="textright responsive-text-24  text-white"
          id="build-team"
        >
          <h2>
            <span className="highlight">Training & Workforce Development</span>
          </h2>
          <p>
            Tailored Training Programs Fostering Employee Skills Productivity,
            And Engagement, Driving Your Organization To Its Success.
          </p>
        </div>
        <div className="textleft responsive-text-24  text-white ">
          <h2>
            <span className="highlight">Quality Control</span>
          </h2>
          <p>
            Robust Quality Assurance Processes, Minimizing Defects Enhancing
            Customer Satisfaction Safeguarding Your Brand’s Reputation.
          </p>
        </div>
        <div
          className="textright responsive-text-24  text-white "
          id="increase-outreach"
        >
          <h2>
            <span className="highlight">Customer Support Management</span>
          </h2>
          <p>
            End-To-End Customer Support Operations Delivering Exceptional
            Service Experiences Driving Customer Loyalty.
          </p>
        </div>
        <div className="textleft responsive-text-24  text-white ">
          <h2>
            <span className="highlight">Operations Support</span>
          </h2>
          <p>
            Optimized Processes, Workflows, And Resource Allocation Enhancing
            Productivity, Profitability, And Competitiveness.
          </p>
        </div>
        <div className="textright responsive-text-24  text-white  ">
          <h2>
            <span className="highlight">System integration</span>
          </h2>
          <p>
            Seamless Integration Of Systems And Applications Driving Efficiency,
            Automation, And Data-Driven Decision-Making.
          </p>
        </div>
        <div
          className="textleft responsive-text-24  text-white "
          id="expand-portfolio"
        >
          <h2>
            <span className="highlight">Business Development</span>
          </h2>
          <p>
            Strategic Planning, Market Analysis Partnership-Building, Fueling
            Growth, And Expansion.
          </p>
        </div>
        <div className="textright responsive-text-24  text-white ">
          <h2>
            <span className="highlight">Supporting Functions</span>
          </h2>
          <p>
            Outsourced Administrative Support, Project Management Regulatory
            Compliance, Maximizing Efficiency And Profitability.
          </p>
        </div>
        <div className="textleft2 d-flex flex-column align-items-center">
          <h2 className="responsive-text-36 text-white">
            <span className="highlight">
              That’s All Possible With Door Closers!
            </span>
          </h2>
          <img src={dcOutsourcinglogo} alt="LOGO DC" className="logodc " />
          <br />
          <br />
          <br />
        </div>
      </section>
      <section className="formtitle" id="form">
        <h2 className="responsive-text-60 text-white text-center width-100">
          Got A Case You’d Like Assistance With?
        </h2>
        <h2 className="responsive-text-36 text-white text-center width-100">
          Just Tell Us About Your Business, Your Goals, And Let’s Start!
        </h2>
        <ContactForm page="outsourcing" />
      </section>
      <Calendar />
    </>
  );
};

export default OutSourcing;
