import React from "react";
import "./BackGroundAnimationColor.css";
const BackGroundAnimationColor = () => {
  return (
    <div className="background-container">
      <div className="bg-shape-1  bg-blur"></div>
      <div className="bg-shape-2  bg-blur"></div>
      <div className="bg-shape-3  bg-blur"></div>{" "}
    </div>
  );
};

export default BackGroundAnimationColor;
