import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./NotFound.css";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 2000); 

    return () => clearTimeout(timer); 
  }, [navigate]);

  return (
    <div className='pageNotFound'>
      <h2>404 - Page Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
      <p>You will be redirected to the homepage shortly.</p>
    </div>
  );
};

export default NotFound;
