import React, { useState } from "react";
import "./Statistic.css";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";

const Statistic = (props) => {
  const [showMiniNumber, setMiniNumber] = useState(false);
  const [inView, setInView] = useState(false);

  const handleEnter = () => {
    setInView(true);
    setMiniNumber(false);
  };

  const handleLeave = () => {
    setInView(false);
  };

  return (
    <Waypoint onEnter={handleEnter} onLeave={handleLeave}>
      <h1 className="d-block text-white counter-container">
        {props.before}
        <span id={props.id} className={`${props.className}`}>
          {showMiniNumber && (props.id === "3millions" || props.id === "500k")
            ? props.id === "3millions"
              ? "3M+"
              : "500k+"
            : inView && (
                <CountUp
                  start={1}
                  end={props.number}
                  duration={7}
                  suffix="+"
                  onEnd={() => setMiniNumber(true)}
                />
              )}
        </span>
        {props.after}
      </h1>
    </Waypoint>
  );
};

export default Statistic;
