import React from "react";
import "./Calendar.css";
import { PopupButton } from "react-calendly";

const Calendar = () => {
  return (
    <section className="text-white responsive-text-36" id="calendar">
      <div className="appointment">
        <PopupButton
          url="https://calendly.com/doorcloserscallcenter/45min"
          rootElement={document.getElementById("root")}
          text=""
          className="calendar"
          pageSettings={{
            backgroundColor: "ffffff",
            hideEventTypeDetails: true,
            hideLandingPageDetails: true,
            hideGdprBanner: true,
            primaryColor: "ff9f00",
            textColor: "000",
          }}
        />
        <div className="text">
          <p>
            Over This 15 Minute Call
            <br />
            we’ll be getting to know each other Identify how you can tackle your
            existing challenges
            <br />
            <span className="highlight">Get you the solutions you need.</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Calendar;
