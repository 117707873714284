import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDbvm9_2fGM0rg1jk_3diPOjznBXtiHieQ",
  authDomain: "door-closers-90dca.firebaseapp.com",
  projectId: "door-closers-90dca",
  storageBucket: "door-closers-90dca.appspot.com",
  messagingSenderId: "866575330941",
  appId: "1:866575330941:web:4277e3d5c22f00ab15cb14",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage, analytics };
