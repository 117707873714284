import React from "react";
import "./Hero.css";
import { HashLink } from "react-router-hash-link";
import { useLoading } from "../../components/Global/LoadingScreen/LoadingContext";

const Hero = () => {
  const { setLoading } = useLoading();
  const handleLoading = () => {
    setLoading(true);
  };
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = window.innerWidth <= 884 ? -70 : -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <section>
      <div className="hero-container first-section-padding full-height">
        <div className="hero-item hero-left-margin">
          <h1 className="hero-title">
            WE
            <div>
              <span className="word ">OPEN</span>
              <span className="word">DEVELOP</span>
              <span className="word">FIND</span>
            </div>
          </h1>
          <h1 className="hero-title mt-3">DOORS FOR</h1>
          <h1 className="hero-title mt-3">YOU TO CLOSE</h1>
          <HashLink
            smooth
            to="/contact#calendar"
            scroll={scrollWithOffset}
            className="hero-btn"
            onClick={handleLoading}
          >
            Let's Elevate Your Business
          </HashLink>
          <div className="social-media-icons ">
            <a
              href="https://www.instagram.com/doorclosers_"
              target="_blank"
              rel="noreferrer"
              aria-label="Visit our Instagram"
            >
              <div className="instagram-icon icon-container"></div>
            </a>
            <a
              href="https://www.facebook.com/doorcloserss"
              target="_blank"
              rel="noreferrer"
              aria-label="Visit our Facebook"
            >
              <div className="facebook-icon icon-container"></div>
            </a>
            <a
              href="https://www.linkedin.com/company/door-closers-cc"
              target="_blank"
              rel="noreferrer"
              aria-label="Visit our LinkedIn"
            >
              <div className="linkedin-icon icon-container"></div>
            </a>
            <a href="mailto:info@doorclosers.org" aria-label="Email us">
              <div className="email-icon icon-container"></div>
            </a>
          </div>
        </div>
        <div className="hero-item brain-container">
          <div className="brain-img"></div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
