import React from "react";
import "./WhyDoorCloosers.css";
import { Waypoint } from "react-waypoint";
import { Reason, Statistic } from "../../components/index";
import { useRef, useState } from "react";
import { useCountUp } from "react-countup";
// import Logo from "../../assets/images/logo.png";
import { HashLink } from "react-router-hash-link";
import { TypeAnimation } from "react-type-animation";
import { useLoading } from "../../components/Global/LoadingScreen/LoadingContext";

const WhyDoorCloosers = () => {
  const waypointRef = useRef(null);
  const [startAnimation, setStartAnimation] = useState(false);
  const { setLoading } = useLoading();
  const handleLoading = () => {
    setLoading(true);
  };
  useCountUp({
    ref: `3`,
    start: 1,
    end: 3,
    duration: 10,
    enableScrollSpy: true,
  });
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = window.innerWidth <= 884 ? -70 : -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <section className="container d-flex flex-column align-items-center">
      <h1 className="text-center text-white reasons-title mb-5">
        WHY DOOR CLOSERS?
      </h1>

      <div className="WhyDoorCloosers-body">
        <Reason
          number="1 "
          title="All in one"
          body="DC Is equipped with a comprehensive 360 toolkit to support and enhance
        your business."
          className="mb-5"
        >
          <Waypoint
            onEnter={() =>
              document
                .getElementById("reason-one-animaton")
                .classList.add("run-animation-one")
            }
            onLeave={() =>
              document
                .getElementById("reason-one-animaton")
                .classList.remove("run-animation-one")
            }
          >
            <div className="d-flex justify-content-end">
              <div
                className="text-white reason-one-animation"
                id="reason-one-animaton"
                ref={waypointRef}
              >
                IF IT'S VIRTUAL, WE CAN DO IT!
              </div>
            </div>
          </Waypoint>
        </Reason>

        <Reason
          number="2 "
          title="Our team"
          body="Served Customers in "
          className="mb-5"
        >
          {/* <div
              className="text-white d-inline reason-two-animation"
              id="reason-two-animaton"
              ref={waypointRef}
            >
              “VARIOUS INDUSTRIES”
            </div> */}
          <Waypoint
            onEnter={() => setStartAnimation(true)}
            onLeave={() => setStartAnimation(false)}
          />
          {startAnimation && (
            <div className="text-white d-inline reason-two-animation">
              <TypeAnimation
                sequence={[
                  "Solar Customers",
                  1000,
                  "Roofing Customers",
                  1000,
                  "Realestate Customers",
                  1000,
                  "Development Customers",
                  1000,
                  "“VARIOUS INDUSTRIES”",
                  1000,
                ]}
                wrapper="span"
                speed={50}
                ref={waypointRef}
              />
            </div>
          )}
        </Reason>

        <div className="row">
          <div className="col-auto">
            <Reason
              number="3 "
              title="Our Portfolio"
              body="speaks for itself."
              className="mb-5"
            />
            <Statistic
              id="3millions"
              number={3000000}
              before="We Made"
              after=" Approximate calls."
              className="counter-t1"
            />
            <Statistic
              id="500k"
              number={500000}
              before="That had"
              after=" Approximate Connects."
              className="counter-t1"
            />
            <Statistic
              id="8600"
              number={8600}
              before="We managed to generate"
              after=" Leads."
              className="counter-t1"
            />
            <Statistic
              id="1221"
              number={1221}
              after=" Appointments."
              className="counter-t2"
            />
            <Statistic
              id="495"
              number={495}
              after=" Closed For Our Customers."
              className="counter-t2"
            />
          </div>
          {/* <div className="container col-auto d-flex justify-content-center">
            <img
              src={Logo}
              alt="DC Logo"
              style={{ width: "250px", height: "250px" }}
            />
          </div> */}
        </div>
        <p className="reason-title text-white mt-5">
          GUESS THE
          <span className="price-animation">
            <span className="d-inline"> </span>
            <span className="price-alpha price-alpha-1 d-inline-block">P</span>
            <span className="price-alpha price-alpha-2 d-inline-block">R</span>
            <span className="price-alpha price-alpha-3 d-inline-block">I</span>
            <span className="price-alpha price-alpha-4 d-inline-block">C</span>
            <span className="price-alpha price-alpha-5 d-inline-block">E</span>
            <span className="d-inline"> </span>
          </span>
          ?
        </p>
        <p className="d-inline text-white reason-body">
          To Build a team, Fully autopilot on a scaling plan, it would averagely
          cost thousands of dollarsWe can guarantee your operational costs, to
          be 50% less at
          <span id="3" className="reason-title ms-3" />
          <Waypoint
            onEnter={() =>
              document
                .getElementById("x-animation")
                .classList.add("x-animation-run")
            }
            onLeave={() =>
              document
                .getElementById("x-animation")
                .classList.remove("x-animation-run")
            }
          >
            <span id="x-animation" className="me-3 ms-1 x-animation ">
              X
            </span>
          </Waypoint>
          More VALUE.
        </p>
        <br />
        <br />
        <HashLink
          smooth
          to="/contact#calendar"
          scroll={scrollWithOffset}
          className="text-white reason-body mt-5 d-inline"
          onClick={handleLoading}
        >
          Let’s get in touch
        </HashLink>
      </div>
    </section>
  );
};

export default WhyDoorCloosers;
