import React from "react";
import "./Reason.css";
const Reason = (props) => {
  return (
    <>
      <div className={`flex-row ${props.className}`}>
        <p className="d-inline reason-title">
          <span className="text-white reason-number">{props.number}</span>
          {props.title}
        </p>
        <h1 className="d-inline text-white reason-body">{props.body}</h1>
        {props.children}
      </div>
    </>
  );
};

export default Reason;
