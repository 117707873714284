import React from "react";
import "./WeCanGetYou.css";
import { HashLink } from "react-router-hash-link";
import { useLoading } from "../../components/Global/LoadingScreen/LoadingContext";

const WeCanGetYou = () => {
  // const scrollWithOffset = (el) => {
  //   const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
  //   const yOffset = window.innerWidth <= 884 ? -70 : -120;
  //   window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  // };
  const { setLoading } = useLoading();
  const handleLoading = () => {
    setLoading(true);
  };
  return (
    <section className="container d-flex flex-column align-items-center seperate-line mt-5">
      <h1 className="why-title d-flex justify-content-center">
        WE CAN GET YOU
      </h1>

      <div className="container">
        <div className="row">
          <div className="col-md-6 mt-5">
            <HashLink
              smooth
              to="/appointments#"
              className="d-flex flex-column justify-content-center align-items-center"
              onClick={handleLoading}
            >
              <h1 className="text-center card-title">APPOINTMENTS</h1>
              <div className="appointment-img card-img"></div>
            </HashLink>
          </div>
          <div className="col-md-6 mt-5">
            <HashLink
              smooth
              to="/leads#"
              className="d-flex flex-column justify-content-center align-items-center"
              onClick={handleLoading}
            >
              <h1 className="text-center card-title">LEADS</h1>
              <div className="leads-img card-img"></div>
            </HashLink>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-5">
            <HashLink
              smooth
              to="/development#"
              className="d-flex flex-column justify-content-center align-items-center"
              onClick={handleLoading}
            >
              <h1 className="text-center card-title">DEVELOPMENT</h1>
              <div className="development-img card-img"></div>
            </HashLink>
          </div>
          <div className="col-md-6 mt-5">
            <HashLink
              smooth
              to="/outsourcing#"
              className="d-flex flex-column justify-content-center align-items-center"
              onClick={handleLoading}
            >
              <h1 className="text-center card-title">OUTSOURCING</h1>
              <div className="outsourcing-img card-img"></div>
            </HashLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeCanGetYou;
