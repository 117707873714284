import React, { useEffect, useState } from "react";
import { BackGroundAnimationColor } from "../../components/index";
import { HashLink } from "react-router-hash-link";
import { useLoading } from "../../components/Global/LoadingScreen/LoadingContext";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const FulfillmentPolicy = () => {
  const [isTablet, setIsTablet] = useState(false);
  const location = useLocation();
  const canonicalUrl = `https://doorclosers.me${location.pathname}`;
  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 884);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { setLoading } = useLoading();
  const handleLoading = () => {
    setLoading(true);
  };
  return (
    <>
      {!isTablet && <BackGroundAnimationColor />}
      <section className="container d-flex flex-column align-items-center first-section-padding text-white outfit-400 px-3">
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
        <h2 className="header-style outfit-900">Fulfillment Policy</h2>
        <p>
          These policies will aid users determine how Door Closers LLC fulfills
          orders.
        </p>
        <p>
          The following terminology applies to these Terms and Conditions,
          Privacy Statement and Disclaimer Notice and all Agreements: “Client”,
          “You” and “Your” refers to you, the person log on this website and
          compliant to the Company’s terms and conditions. “The Company”,
          “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”,
          “Parties”, or “Us”, refers to both the Client and ourselves. All terms
          refer to the offer, acceptance and consideration of payment necessary
          to undertake the process of our assistance to the Client in the most
          appropriate manner for the express purpose of meeting the Client’s
          needs in respect of provision of the Company’s stated services, in
          accordance with and subject to, prevailing law of Singapore. Any use
          of the above terminology or other words in the singular, plural,
          capitalization and/or he/she or they, are taken as interchangeable and
          therefore as referring to same.
        </p>
        <ol>
          <li>
            <h3 className="header-style outfit-900">Introduction</h3>
            <p>
              These policies outline how Door Closers LLC fulfills orders and
              handles refunds for services provided. By accessing this website
              and engaging with Door Closers LLC, you agree to adhere to the
              terms and conditions outlined in this policy.
            </p>
          </li>
          <li>
            <h3 className="header-style outfit-900">Delivery Policy</h3>
            <p>
              At Door Closers LLC, we sell virtual products and provide
              services. Our products are delivered to users via Emails, Facebook
              Messenger, Instagram Messenger, WhatsApp, Skype, etc., tailored to
              suit your business needs.
            </p>
          </li>
          <li>
            <h3 className="header-style outfit-900">Portfolio Usage Rights</h3>
            <p>
              By utilizing our services, you consent to allow us to display the
              completed product on our website or any other platforms for
              marketing purposes.
            </p>
          </li>
          <li>
            <h3 className="header-style outfit-900">
              Unlimited Revisions for Logo and Graphic Designs Services
            </h3>
            <p>
              We offer unlimited revisions for selected services, where
              applicable, for a period of 14 days from the delivery of the
              design concepts. Unlimited revisions include adjustments to text
              size, positioning, colors, and other reasonable amendments,
              subject to approval by Door Closers LLC. New design concepts may
              be subject to additional service fees.
            </p>
          </li>
          <li>
            <h3 className="header-style outfit-900">Refunds</h3>
            <p>We may offer refunds under the following conditions:</p>
            <ul>
              <li>If the service has not been performed.</li>
              <li>
                If the service performed differs from the one ordered or
                requested.
              </li>
              <li>
                If an error occurred on our part, a full refund will be issued.
              </li>
            </ul>
            <p>
              Customers may request a refund within 7 days following receipt of
              the service.
            </p>
          </li>
          <li>
            <h3 className="header-style outfit-900">Exceptions</h3>
            <p>
              We reserve the right to refuse returns or refunds in certain
              situations, including:
            </p>
            <ul>
              <li>Change of mind about the service.</li>
              <li>Awareness of problems before purchase.</li>
              <li>Dislike or lack of use of the service/product.</li>
              <li>Failure to report issues within 7 days of receipt.</li>
            </ul>
          </li>
          <li>
            <h3 className="header-style outfit-900">Return Policy</h3>
            <p>
              At Door Closers LLC, we do not accept returns for virtual
              products, but refunds may be available under specific
              circumstances outlined in our refund policy.
            </p>
          </li>
          <li>
            <h3 className="header-style outfit-900">Cancellation Policy</h3>
            <p>Cancellation terms include:</p>
            <ul>
              <li>Full refund if work has not commenced.</li>
              <li>
                Retention of an appropriate portion of the payment if work has
                started.
              </li>
            </ul>
          </li>
          <li>
            <h3 className="header-style outfit-900">Project Abandonment</h3>
            <p>
              If Client fails to participate or becomes unresponsive for a
              period of thirty (30) days after repeated attempts by Door Closers
              LLC to deliver services, the project may be abandoned at the
              discretion of Door Closers LLC.
            </p>
          </li>
          <li>
            <h3 className="header-style outfit-900">Administrative Fee</h3>
            <p>All refunds are subject to a 7% administrative fee.</p>
          </li>
          <li>
            <h3 className="header-style outfit-900">Complaint Procedure</h3>
            <p>
              If dissatisfied with the service and eligible for a refund,
              contact us at
              <HashLink
                smooth
                to="/contact#"
                className="link-style mx-2"
                onClick={handleLoading}
              >
                https://{window.location.host}/contact
              </HashLink>
              or email info@doorclosers.org. Refunds are issued at our
              discretion.
            </p>
          </li>
          <li>
            <h3 className="header-style outfit-900">Contact Us</h3>
            <p>
              For inquiries or comments regarding this policy, please email
              info@doorclosers.org.
            </p>
          </li>
        </ol>
      </section>
    </>
  );
};

export default FulfillmentPolicy;
