import React from "react";
import "./NavBarLink.css";
import { HashLink } from "react-router-hash-link";
import { useLoading } from "../../Global/LoadingScreen/LoadingContext";

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
  const yOffset = window.innerWidth <= 884 ? -70 : -120;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const NavBarLink = (props) => {
  const { setLoading } = useLoading();
  const handleLoading = () => {
    setLoading(true);
  };
  return (
    <div className="nav-bar-link-container">
      {/* <HashLink
        smooth
        to={`/${props.to}#`}
        className={`nav-bar-link-font ${props.titleClassName}`}
      > */}
      <div className={`nav-bar-link-font ${props.titleClassName}`}>
        {props.title}
      </div>

      {/* </HashLink> */}
      <div
        className={`nav-bar-link-items-container ${props.containerClassName}`}
      >
        {props.items.map((item, index) => {
          return (
            <HashLink
              smooth
              to={`/${item.href}`}
              key={index}
              scroll={scrollWithOffset}
              className="nav-bar-link-items-font"
              onClick={handleLoading}
            >
              {item.isNew ? (
                <>
                  {item.label}
                  <span className="new-style">{"  "}[NEW]</span>
                </>
              ) : (
                item.label
              )}
            </HashLink>
          );
        })}
      </div>
    </div>
  );
};

export default NavBarLink;
